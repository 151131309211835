export const ClaimIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="mask0_40922_28954" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_40922_28954)">
        <path
          d="M12.925 11.0492L8.675 6.79922L10.1 5.39922L12.925 8.22422L18.6 2.57422L20 3.97422L12.925 11.0492ZM7 18.4992L13.95 20.3992L19.9 18.5492C19.8167 18.3992 19.6958 18.2701 19.5375 18.1617C19.3792 18.0534 19.2 17.9992 19 17.9992H13.95C13.5 17.9992 13.1417 17.9826 12.875 17.9492C12.6083 17.9159 12.3333 17.8492 12.05 17.7492L9.725 16.9742L10.275 15.0242L12.3 15.6992C12.5833 15.7826 12.9167 15.8492 13.3 15.8992C13.6833 15.9492 14.25 15.9826 15 15.9992C15 15.8159 14.9458 15.6409 14.8375 15.4742C14.7292 15.3076 14.6 15.1992 14.45 15.1492L8.6 12.9992H7V18.4992ZM1 21.9992V10.9992H8.6C8.71667 10.9992 8.83333 11.0117 8.95 11.0367C9.06667 11.0617 9.175 11.0909 9.275 11.1242L15.15 13.2992C15.7 13.4992 16.1458 13.8492 16.4875 14.3492C16.8292 14.8492 17 15.3992 17 15.9992H19C19.8333 15.9992 20.5417 16.2742 21.125 16.8242C21.7083 17.3742 22 18.0992 22 18.9992V19.9992L14 22.4992L7 20.5492V21.9992H1ZM3 19.9992H5V12.9992H3V19.9992Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
