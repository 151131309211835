import { baseLocale } from '@/app/context/LocalizationContext';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils/contentful';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { EcosystemDappCategoryEntry, EcosystemDappCategorySkeleton } from '../types';

const getNestedParentCategory = (category: any, level = 0) => {
  const currentLevelCategory = category?.[baseLocale]?.fields;

  if (level === 0) return currentLevelCategory?.name?.[baseLocale];

  if (currentLevelCategory?.parentCategory) {
    return getNestedParentCategory(currentLevelCategory?.parentCategory, level - 1);
  } else {
    return currentLevelCategory?.name?.[baseLocale];
  }
};

const fetchEcosystemCategories = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<EcosystemDappCategorySkeleton>({
          content_type: 'categories',
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<EcosystemDappCategoryEntry[]>>(
          `${localConfig.contentfulUrl}/categories.json`
        )
      ).data;
    }

    return content.reduce((acc, entry) => {
      const { name, parentCategory } = entry.fields;

      if (!name?.[baseLocale]) return acc;

      const output = {
        ...acc,
        [name?.[baseLocale]]: {
          parentCategory: parentCategory ? getNestedParentCategory(parentCategory, 0) : '',
        },
      };

      return output;
    });
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useEcosystemCategories = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.ECOSYSTEM_CATEGORIES],
    queryFn: fetchEcosystemCategories,
    ...options,
  });
};
