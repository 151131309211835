import localConfig from '@/config';
import { opStackL1Contracts_MAINNET, opStackL1Contracts_TESTNET } from '@/config/crossChainMessanger';
import { currencyOptions } from '@/constants';
import { MODE_ENV } from '@/constants/common';
import { providers } from '@/utils';
import {
  CrossChainMessage,
  CrossChainMessenger,
  ETHBridgeAdapter,
  MessageLike,
  StandardBridgeAdapter,
  TokenBridgeMessage,
  TransactionLike,
} from '@eth-optimism/sdk';

class CustomCrossChainMessenger extends CrossChainMessenger {
  public async toCrossChainMessage(message: MessageLike): Promise<CrossChainMessage> {
    if (!message) {
      throw new Error('message is undefined');
    }
    // TODO: Convert these checks into proper type checks.
    if ((message as CrossChainMessage).message) {
      return message as CrossChainMessage;
    } else if (
      (message as TokenBridgeMessage).l1Token &&
      (message as TokenBridgeMessage).l2Token &&
      (message as TokenBridgeMessage).transactionHash
    ) {
      const messages = await this.getMessagesByTransaction((message as TokenBridgeMessage).transactionHash);
      // The `messages` object corresponds to a list of SentMessage events that were triggered by
      // the same transaction. We want to find the specific SentMessage event that corresponds to
      // the TokenBridgeMessage (either a ETHDepositInitiated, ERC20DepositInitiated, or
      // WithdrawalInitiated event). We expect the behavior of bridge contracts to be that these
      // TokenBridgeMessage events are triggered and then a SentMessage event is triggered. Our
      // goal here is therefore to find the first SentMessage event that comes after the input
      // event.
      const found = messages
        .sort((a, b) => {
          // Sort all messages in ascending order by log index.
          return a.logIndex - b.logIndex;
        })
        .find((m) => m.target !== null);

      if (!found) {
        throw new Error(`could not find SentMessage event for message`);
      }

      return found;
    } else {
      // TODO: Explicit TransactionLike check and throw if not TransactionLike
      const messages = await this.getMessagesByTransaction(message as TransactionLike);

      // We only want to treat TransactionLike objects as MessageLike if they only emit a single
      // message (very common). It's unintuitive to treat a TransactionLike as a MessageLike if
      // they emit more than one message (which message do you pick?), so we throw an error.
      if (messages.length !== 1) {
        throw new Error(`expected 1 message, got ${messages.length}`);
      }

      return messages[0];
    }
  }
}

const L1Addresses = localConfig.modeEnv === MODE_ENV.MAINNET ? opStackL1Contracts_MAINNET : opStackL1Contracts_TESTNET;

const L1Contracts = {
  StateCommitmentChain: '0x0000000000000000000000000000000000000000',
  CanonicalTransactionChain: '0x0000000000000000000000000000000000000000',
  BondManager: '0x0000000000000000000000000000000000000000',
  AddressManager: L1Addresses.Lib_AddressManager,
  L1CrossDomainMessenger: L1Addresses.Proxy__OVM_L1CrossDomainMessenger,
  L1StandardBridge: L1Addresses.Proxy__OVM_L1StandardBridge,
  OptimismPortal: L1Addresses.OptimismPortalProxy,
  L2OutputOracle: L1Addresses.L2OutputOracleProxy,
};

const defaultBridges = {
  Standard: {
    l1Bridge: L1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: StandardBridgeAdapter,
  },
  ETH: {
    l1Bridge: L1Contracts.L1StandardBridge,
    l2Bridge: '0x4200000000000000000000000000000000000010',
    Adapter: ETHBridgeAdapter,
  },
};

export const getCrossChainMessenger = async (
  l1signer: any,
  l2signer: any,
  l1provider?: any,
  l2provider?: any
): Promise<CrossChainMessenger> => {
  return new CrossChainMessenger({
    bedrock: true,
    l1SignerOrProvider: l1signer || l1provider,
    l2SignerOrProvider: l2signer || l2provider,
    l1ChainId: localConfig.l1ChainId,
    l2ChainId: localConfig.l2ChainId,
    bridges: defaultBridges,
    contracts: {
      l1: L1Contracts,
    },
  });
};

export const getWstEthCrossChainMessenger = async (): Promise<CrossChainMessenger> => {
  const wstEthToken = currencyOptions.find((value) => value.label === 'wstETH');

  const wstEthBridge = {
    Standard: {
      l1Bridge: wstEthToken?.l1Bridge ? wstEthToken.l1Bridge : '0x16B929D35B200EA0ae0B93EABc3Bf9Ad611BF18F',
      l2Bridge: wstEthToken?.l2Bridge ? wstEthToken.l2Bridge : '0xd41a90e55bcfC1CbF96D78aE80BbCB56A6BA0008',
      Adapter: StandardBridgeAdapter,
    },
  };

  return new CustomCrossChainMessenger({
    bedrock: true,
    l1SignerOrProvider: providers.l1,
    l2SignerOrProvider: providers.l2,
    l1ChainId: localConfig.l1ChainId,
    l2ChainId: localConfig.l2ChainId,
    bridges: wstEthBridge,
    contracts: {
      l1: L1Contracts,
    },
  });
};
