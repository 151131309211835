export const SwitchIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 7V2H11L11 7H13ZM6 12.0012C6 9.48471 7.5495 7.32776 9.75022 6.43694L8.99979 4.58306C6.0698 5.76909 4 8.64222 4 12.0012C4 16.4195 7.58172 20.0012 12 20.0012C16.4183 20.0012 20 16.4195 20 12.0012C20 8.64222 17.9302 5.76909 15.0002 4.58306L14.2498 6.43694C16.4505 7.32776 18 9.48471 18 12.0012C18 15.3149 15.3137 18.0012 12 18.0012C8.68629 18.0012 6 15.3149 6 12.0012Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};
