export const RESTRICTED_COUNTRIES = [
  'AF',
  'AS',
  'AO',
  'AM',
  'AZ',
  'BY',
  'BA',
  'BW',
  'BI',
  'KH',
  'CM',
  'CA',
  'CF',
  'TD',
  'CU',
  'KP',
  'CD',
  'ER',
  'ET',
  'GH',
  'GU',
  'GN',
  'GW',
  'HT',
  'IR',
  'IQ',
  'JP',
  'LA',
  'LB',
  'LR',
  'LY',
  'MG',
  'ML',
  'MZ',
  'MM',
  'NI',
  'MP',
  'PK',
  'CN',
  'PR',
  'CG',
  'RU',
  'SO',
  'SS',
  'LK',
  'SD',
  'SY',
  'TJ',
  'TT',
  'TM',
  'UG',
  'GB',
  'VU',
  'VE',
  'VI',
  'YE',
  'ZW',
];
