import axios from 'axios';
import { parseBigNumberToFloat, weiToEth } from './format';
import localConfig from '@/config';
import { BigNumber } from 'ethers';
export interface TokenDetails {
  id: string;
  owner: string;
  'available-balance': number;
  'total-revenue': number;
  'current-epoch-revenue': number;
  contracts: string[];
  hasPendingTransaction?: boolean;
}

export const fetchTransactionsForToken = async (tokenId: number) => {
  const res = await axios.get(`${localConfig.sfsBaseUrl}/tokens/${tokenId}/transactions`);

  return res.data;
};

export const getTokensIds = async (address: string): Promise<string[]> => {
  const res = await axios.get(`${localConfig.sfsBaseUrl}/tokens/wallet/${address}`);

  return res.data;
};

export const getTokenDetails = async (token_id: string) => {
  const res = await axios.get(`${localConfig.sfsBaseUrl}/tokens/${token_id}`);

  return res.data;
};

export const fetchTokensDetails = async (ids: string[], func: (tokenDetail: TokenDetails[]) => void) => {
  try {
    const results: TokenDetails[] = await Promise.all(ids.map((tokenId) => getTokenDetails(tokenId)));

    func(results);
  } catch (error) {
    console.error('error', error);
  }
};

export const getTotals = (
  tokenDetails: TokenDetails[] | undefined
): {
  totalTotalRevenue: string;
  totalCurrentEpochRevenue: string;
  totalAvailableBalance: string;
} => {
  if (!tokenDetails) {
    return {
      totalTotalRevenue: '0',
      totalCurrentEpochRevenue: '0',
      totalAvailableBalance: '0',
    };
  }

  const totalsMapped = tokenDetails.reduce(
    (acc, item) => {
      acc.totalTotalRevenue += item['total-revenue'];
      acc.totalCurrentEpochRevenue += item['current-epoch-revenue'];
      acc.totalAvailableBalance += item['available-balance'];
      return acc;
    },
    {
      totalTotalRevenue: 0,
      totalCurrentEpochRevenue: 0,
      totalAvailableBalance: 0,
    }
  );

  return {
    totalTotalRevenue: weiToEth(totalsMapped.totalTotalRevenue),
    totalCurrentEpochRevenue: weiToEth(totalsMapped.totalCurrentEpochRevenue),
    totalAvailableBalance: weiToEth(totalsMapped.totalAvailableBalance),
  };
};
