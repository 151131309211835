import { useCallback } from 'react';
import { useConfig, UseConfigReturnType } from 'wagmi';
import { providers } from 'ethers';
import { getWalletClient } from '@wagmi/core';

export async function walletClientToSigner(config: UseConfigReturnType, chainId?: number) {
  const walletClient = await getWalletClient(config, {
    chainId,
  });

  const { account, chain, transport } = walletClient;
  if (!chain || !account) return;

  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const config = useConfig();
  return useCallback(() => (config ? walletClientToSigner(config, chainId) : undefined), [config, chainId]);
}
