export const SquareArrowTopRightIcon = ({ className, size = 12 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1.5H10.5V5.5H9.75V2.78033L5.5 7.03033L4.96967 6.5L9.21967 2.25H6.5V1.5ZM1.5 2.5H5V3.25H2.25V9.75H8.75V6.625H9.5V10.5H1.5V2.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
