import { UnmarshalSupportedNftNames, UnmarshalSupportedProtocolNames } from '@/services/unmarshal/index.types';

const publicFolder = '/cryptoIcons';
export const nftAndProtocolMap: Record<UnmarshalSupportedNftNames | UnmarshalSupportedProtocolNames, string> = {
  // NFTs
  LobsterDAO: publicFolder + '/lobster_dao.svg',
  Hedgies: publicFolder + '/hedgies.svg',
  SmolBrains: publicFolder + '/smolbrains.svg',
  'Bong Bears': publicFolder + '/bong_bears.svg',
  'Bond Bears': publicFolder + '/bond_bears.svg',
  'Boo Bears': publicFolder + '/boo_bears.svg',
  BAYC: publicFolder + '/bayc.svg',
  'Pudgy Penguins': publicFolder + '/pudgy_penguins.svg',
  'EigenLayer World Builders': publicFolder + '/eigenlayer.svg',
  DeGods: publicFolder + '/degods.svg',
  'Milady Maker': publicFolder + '/milady_maker.svg',
  Azuki: publicFolder + '/azuki.svg',
  Moonbirds: publicFolder + '/moonbirds.svg',
  Doodles: publicFolder + '/doodles.svg',
  Meebits: publicFolder + '/meebits.svg',
  'ENS Holders': publicFolder + '/ens.svg',
  mfers: publicFolder + '/mfers.svg',
  rektguy: publicFolder + '/rektguy.svg',
  'World of Women': publicFolder + '/wow.svg',
  'goblintown.wtf': publicFolder + '/goblintown.svg',
  Wassies: publicFolder + '/wassies.svg',
  Cryptodickbutts: publicFolder + '/cryptodickbutts.svg',
  Beanz: publicFolder + '/beanz.svg',
  MAYC: publicFolder + '/mayc.svg',
  Nakamigos: publicFolder + '/nakamigos.svg',
  EigenWorlds: publicFolder + '/',
  Cryptopunks: publicFolder + '/',

  // Protocols
  Uniswap: publicFolder + '/uniswap.svg',
  Uniswap_V1: publicFolder + '/uniswap.svg',
  Uniswap_V2: publicFolder + '/uniswap.svg',
  Uniswap_V3: publicFolder + '/uniswap.svg',
  Lido: publicFolder + '/lido.svg',
  Maker: publicFolder + '/maker.svg',
  Aave: publicFolder + '/aave.svg',
  Compound: publicFolder + '/compound.svg',
  Curve: publicFolder + '/curve.svg',
  Eigenlayer: publicFolder + '/eigenlayer.svg',
  dydx: publicFolder + '/dydx.svg',
  Yearn: publicFolder + '/yearn.svg',
  Sushi: publicFolder + '/sushi.svg',
  '1inch': publicFolder + '/1inch.svg',
  'Optimism bridge': publicFolder + '/op.svg',
  'Arbitrum bridge': publicFolder + '/arb.svg',
  ENS: publicFolder + '/ens.svg',
  Pancakeswap: publicFolder + '/pancake.svg',
  Chainlink: publicFolder + '/link.svg',
  Gitcoin: publicFolder + '/gitcoin.svg',
  'Injective Protocol': publicFolder + '/inj.svg',
  Polkastarter: publicFolder + '/polkastarter.svg',
  Aori: publicFolder + '/aori.svg',
  Lyra: publicFolder + '/lyra.svg',
  GMX: publicFolder + '/gmx.svg',
  Synthetix: publicFolder + '/synthetix.svg',
  Pyth: publicFolder + '/pyth.svg',
  'Summer.fi': publicFolder + '/summerfi.svg',
  Aura: publicFolder + '/aura.svg',
  Velodrome: publicFolder + '/velodrome.svg',
  'Trader Joe': publicFolder + '/trader_joe.svg',
  'Gains Network': publicFolder + '/gains.svg',
  Camelot: publicFolder + '/camelot.svg',
  Pendle: publicFolder + '/pendle.svg',
  Seedify: publicFolder + '/',
  'Ethena stakers': publicFolder + '/',
  'Manta bridge': publicFolder + '/',
  Blast: publicFolder + '/',
  Rocketpool: publicFolder + '/',
  'DAO Maker': publicFolder + '/',
  'Frax Finance': publicFolder + '/',
};
