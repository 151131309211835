import { baseLocale } from '@/app/context/LocalizationContext';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils/contentful';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { EcosystemAppEntry, EcosystemAppSkeleton, EcosystemOrderSkeleton } from '../types';

export const fetchEcosystemApps = async () => {
  try {
    let content: EcosystemAppEntry[] = [];

    if (localConfig.showContentLive) {
      const order = (
        await contentfulClient.getEntries<EcosystemOrderSkeleton>({
          content_type: 'ecosystemOrder',
        })
      ).items[0];

      content = (
        await contentfulClient.withAllLocales.getEntries<EcosystemAppSkeleton>({
          content_type: 'apps',
        })
      ).items.sort((a, b) => {
        return (
          order.fields.order.order.indexOf((a.fields as any).id[baseLocale]) -
          order.fields.order.order.indexOf((b.fields as any).id[baseLocale])
        );
      });
    } else {
      content = (
        await axios.get<void, AxiosResponse<EcosystemAppEntry[]>>(
          `${localConfig.contentfulUrl}/appsWithAllLocales.json`
        )
      ).data;
    }

    return content.map((entry) => {
      const {
        auditorInfo,
        auditors,
        badges,
        brandColor,
        category,
        id,
        isParticipated,
        isPending,
        link,
        logo,
        name,
        orbId,
        photonId,
        projectTotalNft,
        safeAddress,
        shortDescription,
        waysToEarnPoints,
      } = entry.fields;

      return {
        auditorInfo: auditorInfo?.[baseLocale] || [],
        auditors: auditors?.[baseLocale]?.map((item) => item.fields) || [],
        badges: badges?.[baseLocale] || [],
        brandColor: brandColor?.[baseLocale],
        category: category?.[baseLocale]?.fields?.category?.[baseLocale] || '',
        id: id?.[baseLocale],
        imageHeight: logo[baseLocale]?.fields?.file?.[baseLocale]?.details?.image?.height || 48,
        imageWidth: logo[baseLocale]?.fields?.file?.[baseLocale]?.details?.image?.width || 48,
        isParticipated: isParticipated?.[baseLocale] || false,
        isPending: isPending?.[baseLocale],
        link: link[baseLocale]!,
        logoSrc: logo[baseLocale]?.fields?.file?.[baseLocale]
          ? `https:${logo[baseLocale]?.fields?.file?.[baseLocale]?.url}`
          : '/spin.svg',
        name: name?.[baseLocale] || '',
        orbId: orbId?.[baseLocale],
        photonId: photonId?.[baseLocale],
        projectTotalNft: projectTotalNft?.[baseLocale],
        safeAddress: safeAddress?.[baseLocale],
        shortDescription,
        waysToEarnPoints: waysToEarnPoints?.[baseLocale],
      };
    });
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useEcosystemApps = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.ECOSYSTEM_APPS],
    queryFn: fetchEcosystemApps,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};

export type EcosystemApp = NonNullable<Awaited<ReturnType<typeof fetchEcosystemApps>>>[number];
