import localConfig from '@/config';
import { queryClient } from '@/config/query';
import { Currency, currencyOptions } from '@/constants';
import erc20_json from '@/constants/abis/ERC20.json';
import { RPC_OVERRIDE_KEY_L1, RPC_OVERRIDE_KEY_L2 } from '@/constants/common';
import { QueryKeys } from '@/constants/queryKeys';
import { mainnetUpdated, mode, modeTestnet, sepoliaUpdated } from '@/mode';
import { getActiveRpc } from '@/utils/wallet';
import { Contract, ethers } from 'ethers';
import fromExponential from 'from-exponential';
import { parseBigNumberToFloat } from './format';

const NON_SUPPORTED_TOKENS = [
  'mBTC',
  'ezETH',
  'weETH.mode',
  'MODE',
  'BBTC',
  'BBUSD',
  'aBTC',
  'vLiSTX',
  'ALEX',
  'uBTC',
  'uniBTC',
];

//this is for filtering the balances
const currenciesToGetBalance: Currency[] = currencyOptions.filter(
  (currency) =>
    currency.label !== 'ETH' &&
    !NON_SUPPORTED_TOKENS.includes(currency.label) &&
    currency.chains.includes(localConfig.modeEnv)
);

export const providers = {
  l1: new ethers.providers.JsonRpcProvider(
    getActiveRpc(
      RPC_OVERRIDE_KEY_L1,
      localConfig.modeEnv === 'MAINNET' ? mainnetUpdated.rpcUrls : sepoliaUpdated.rpcUrls
    )?.default.http[0] ?? ''
  ),
  l2: new ethers.providers.JsonRpcProvider(
    getActiveRpc(RPC_OVERRIDE_KEY_L2, localConfig.modeEnv === 'MAINNET' ? mode.rpcUrls : modeTestnet.rpcUrls)?.default
      .http[0] ?? ''
  ),
};

const getTokenBalances = async (address: string) => {
  const getBalance = async (currency: Currency, layer: 'l1' | 'l2') => {
    try {
      const token = new Contract(currency[layer], erc20_json.abi, providers[layer]);
      const tokenBalance = await token.balanceOf(address);

      return { [currency.label]: fromExponential(parseBigNumberToFloat(tokenBalance, currency.decimals)) };
    } catch (error) {
      console.error(`Error fetching ${currency.label} ${currency[layer]} balance:`, error);
      return {};
    }
  };

  const l1Balances = await Promise.all(
    currenciesToGetBalance.map(async (currency) => await getBalance(currency, 'l1'))
  );

  const l2Balances = await Promise.all(
    currenciesToGetBalance.map(async (currency) => await getBalance(currency, 'l2'))
  );

  return { l1Balances, l2Balances };
};

export const fetchTokenBalances = (address: string) => {
  return queryClient.fetchQuery({
    queryKey: [QueryKeys.TOKEN_BALANCES, address],
    queryFn: () => getTokenBalances(address),
  });
};

export default fetchTokenBalances;
