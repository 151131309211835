export const FileTextIcon = ({ className, size = 16 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66406 1.3335H8.7045L13.3307 5.95972V14.6668H2.66406V1.3335ZM3.66406 2.3335V13.6668H12.3307V6.66683H7.9974V2.3335H3.66406ZM8.9974 3.0406L11.6236 5.66683H8.9974V3.0406ZM5.33073 8.3335H8.66406V9.3335H5.33073V8.3335ZM5.33073 11.0002H10.6641V12.0002H5.33073V11.0002Z"
        fill="currentColor"
      />
    </svg>
  );
};
