import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { FaqItemEntry, FaqItemSkeleton } from '../types';

const fetchFaqItems = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<FaqItemSkeleton>({
          content_type: 'faqItem',
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<FaqItemEntry[]>>(`${localConfig.contentfulUrl}/faqItemWithAllLocales.json`)
      ).data;
    }
    return Array.isArray(content)
      ? content.map((item) => item.fields).sort((a, b) => (a.order as number) - (b.order as number))
      : [];
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useFaqItems = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.FAQ_ITEMS],
    queryFn: fetchFaqItems,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};
