import { QueryKeys } from '@/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import localConfig from '@/config';
import { contentfulClient } from '@/utils/contentful';
import { GuidesItemSkeleton } from '../types/GuidesItem';

const fetchGuidesItems = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<GuidesItemSkeleton>({
          content_type: 'guides',
        })
      ).items;
    } else {
      content = (await axios.get<void, AxiosResponse<GuidesItemSkeleton[]>>(`${localConfig.contentfulUrl}/guides.json`))
        .data;
    }

    return content.map((entry) => {
      const { id, steps } = entry.fields;

      return {
        id: id?.['en-US'],
        steps,
      };
    });
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useGuidesItems = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.GUIDES],
    queryFn: fetchGuidesItems,
    ...options,
  });
};
