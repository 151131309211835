export const ChevronIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 12.5859L8.70704 9.29297L7.29285 10.7072L11.293 14.7072L12.0001 15.4143L12.7072 14.7072L16.7072 10.7072L15.293 9.29298L12.0001 12.5859Z"
        fill="currentColor"
      />
    </svg>
  );
};
