export const BPTWhiteIcon = ({ className, size = 14 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clipPath="url(#clip0_1210_39192)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 0C10.8654 0 14 3.1346 14 7C14 10.8654 10.8654 14 7 14C3.1346 14 0 10.8654 0 7C0 3.1346 3.1346 0 7 0Z"
          fill="white"
        />
        <path
          d="M7.10769 9.92604C4.86909 9.92604 3.05469 9.27924 3.05469 8.41264C3.05469 7.96044 3.54889 7.55304 4.33989 7.26604C4.95729 7.66224 5.99329 7.86524 7.16789 7.86524C8.31449 7.86524 9.32809 7.61744 9.95109 7.23804C10.6973 7.52224 11.1607 7.91844 11.1607 8.35524C11.1607 9.22324 9.34629 9.92604 7.10769 9.92604Z"
          fill="#1E1E1E"
        />
        <path
          d="M7.12769 7.67051C5.43089 7.67051 4.05469 7.13851 4.05469 6.48191C4.05469 6.11791 4.47889 5.79171 5.14389 5.57471C5.61849 5.82251 6.32969 5.98071 7.12769 5.98071C7.92569 5.98071 8.63689 5.82251 9.11149 5.57471C9.77789 5.79311 10.2007 6.11791 10.2007 6.48191C10.2021 7.13851 8.82589 7.67051 7.12769 7.67051Z"
          fill="#1E1E1E"
        />
        <path
          d="M7.11799 5.78349C5.80619 5.78349 4.74219 5.34389 4.74219 4.80349C4.74219 4.26309 5.80619 3.82349 7.11799 3.82349C8.42979 3.82349 9.49379 4.26309 9.49379 4.80349C9.49379 5.34389 8.42979 5.78349 7.11799 5.78349Z"
          fill="#1E1E1E"
        />
      </g>
      <defs>
        <clipPath id="clip0_1210_39192">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
