import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from '@/constants/queryKeys';
import axios, { AxiosResponse } from 'axios';
import localConfig from '@/config';

export type MerklDappRewardsEntity = {
  dapp_merkl_id: number;
  reward_tokens: Array<{
    reward_token_name: string;
    reward_token_amount: number;
    reward_token_address: string;
  }>;
};

const fetcher = async () => {
  try {
    const { data: merklDappsRewards } = await axios.get<void, AxiosResponse<MerklDappRewardsEntity[]>>(
      `${localConfig.apiUrl}/merkl/dapps`
    );

    return merklDappsRewards;
  } catch (error) {
    console.error('error', error);

    return;
  }
};

export const useMerklDappsRewards = (options = {}) => {
  return useQuery<MerklDappRewardsEntity[] | undefined>({
    queryKey: [QueryKeys.MERKL_DAPPS],
    queryFn: async () => {
      return fetcher();
    },
    ...options,
  });
};
