export enum MODE_ENV {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET',
}

export enum BRIDGE_ENV {
  MAINNET = 'MAINNET',
  TESTNET = 'TESTNET',
  TESTNET_BRIDGE = 'TESTNET_BRIDGE',
  MAINNET_BRIDGE = 'MAINNET_BRIDGE',
}

export const USER_LOCATION_WARNING = 'USER_LOCATION_WARNING';

export const RPC_OVERRIDE_KEY = 'MODE_RPC_OVERRIDE';
export const RPC_OVERRIDE_KEY_L1 = 'MODE_RPC_OVERRIDE_L1';
export const RPC_OVERRIDE_KEY_L2 = 'MODE_RPC_OVERRIDE_L2';
