export const InitiateWithdrawalIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="mask0_40922_28926" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_40922_28926)">
        <path
          d="M12 3C13.2333 3 14.3958 3.2375 15.4875 3.7125C16.5792 4.1875 17.5333 4.83333 18.35 5.65C19.1667 6.46667 19.8125 7.42083 20.2875 8.5125C20.7625 9.60417 21 10.7667 21 12C21 13.2333 20.7625 14.3958 20.2875 15.4875C19.8125 16.5792 19.1667 17.5333 18.35 18.35C17.5333 19.1667 16.5792 19.8125 15.4875 20.2875C14.3958 20.7625 13.2333 21 12 21C11.3167 21 10.6583 20.925 10.025 20.775C9.39167 20.625 8.75833 20.4083 8.125 20.125L9.65 18.6C10.0333 18.7333 10.4208 18.8333 10.8125 18.9C11.2042 18.9667 11.6 19 12 19C13.9333 19 15.5833 18.3167 16.95 16.95C18.3167 15.5833 19 13.9333 19 12C19 10.0667 18.3167 8.41667 16.95 7.05C15.5833 5.68333 13.9333 5 12 5C10.0667 5 8.41667 5.68333 7.05 7.05C5.68333 8.41667 5 10.0667 5 12C5 12.4 5.03333 12.7958 5.1 13.1875C5.16667 13.5792 5.26667 13.9667 5.4 14.35L3.9 15.85C3.6 15.25 3.375 14.6292 3.225 13.9875C3.075 13.3458 3 12.6833 3 12C3 10.7667 3.2375 9.60417 3.7125 8.5125C4.1875 7.42083 4.83333 6.46667 5.65 5.65C6.46667 4.83333 7.42083 4.1875 8.5125 3.7125C9.60417 3.2375 10.7667 3 12 3ZM13 16V12.4L4.4 21L3 19.6L11.6 11H8V9H15V16H13Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
