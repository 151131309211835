import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import * as ethers from 'ethers';
import { formatDistanceToNow, parseISO } from 'date-fns';

export function parseBigNumberToFloat(val: BigNumber, decimals = 18) {
  if (!val) {
    return 0;
  }

  const formatted = formatUnits(val, decimals);
  const parsed = parseFloat(formatted);
  return parsed;
}

export const shortAddress = (text: string, startLength: number = 8, endLength: number = 8) => {
  if (text.length <= startLength + endLength) {
    return text;
  }

  return text.substring(0, startLength) + '...' + text.substring(text.length - endLength);
};

export const formatWalletAddress = (
  address: string | undefined | null,
  firstBreakpoint = 6,
  secondBreakpoint = 4
): string => {
  if (!address) {
    return '';
  }

  if (address.length > 25) {
    return `${address.slice(0, firstBreakpoint)}...${address.slice(address.length - secondBreakpoint, address.length)}`;
  }

  return address;
};

export const timeAgo = (time: string) => {
  const date = parseISO(time);

  return formatDistanceToNow(date, {
    addSuffix: true,
  });
};

export function hexToBase64(hex: string) {
  if (hex.startsWith('0x')) {
    hex = hex.slice(2);
  }

  const codes: number[] = [];

  for (let i = 0; i < hex.length; i += 2) {
    codes.push(parseInt(hex.slice(i, i + 2), 16));
  }

  return btoa(String.fromCharCode(...codes));
}

export function weiToEth(weiInput: ethers.ethers.BigNumberish) {
  const i = weiInput.toString().indexOf('.');
  if (i !== -1) {
    weiInput = BigInt(weiInput.toString().substring(0, i));
  }
  // Using the built-in utility function to handle the conversion
  const ethString = ethers.utils.formatEther(BigInt(weiInput.toString()));
  const ethTruncatedString = ethString.match(/^-?\d+(?:\.\d{0,6})?/)![0];
  const eth = parseFloat(ethTruncatedString).toString();
  return eth;
}

export const toOptionalFixed = (num: number, digits: number) => {
  const minValue = Number(`0.${new Array(digits - 1 < 0 ? 0 : digits - 1).fill(0).join('')}1`);

  if (+num === 0) {
    return '0';
  }

  if (num < minValue) {
    return `<${minValue}`;
  }

  if (num === minValue) {
    return `${num}`;
  }

  const val = Number.parseFloat((+num).toFixed(digits));

  return `${val}`;
};

export const formatCurrency = (amount?: number | string, maximumFractionDigits?: number) => {
  const formattedAmount = Number(amount || 0).toLocaleString(undefined, { maximumFractionDigits });
  const decimals = formattedAmount.split('.')[1];
  return decimals == null && maximumFractionDigits === 2 ? `${formattedAmount}.00` : formattedAmount;
};

export function abbreviateNumber(amount: number | string) {
  const number = Number(amount || 0);

  if (number <= 1000) {
    return formatCurrency(number, 2);
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const suffixCount = suffixes.length;

  const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

  const tierIndex = Math.min(tier, suffixCount - 1);

  const scaled = number / Math.pow(10, tierIndex * 3);

  const formatted = scaled.toFixed(2).replace(/\.00$/, '');

  return formatted + suffixes[tierIndex];
}

export const formatNumber = (num: number): string => {
  const standardFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return standardFormatter.format(num);
};
