import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { PointsEntry, PointsSkeleton } from '../types';

const fetchPoints = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.getEntries<PointsSkeleton>({
          content_type: 'points',
        })
      ).items;
    } else {
      content = (await axios.get<void, AxiosResponse<PointsEntry[]>>(`${localConfig.contentfulUrl}/points.json`)).data;
    }

    return content[0].fields;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const usePoints = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.POINTS],
    queryFn: fetchPoints,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};

export type Points = NonNullable<Awaited<ReturnType<typeof fetchPoints>>>;
