import { QueryKeys } from '@/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

type CoinGeckoTokenInfo = {
  data: Array<{
    attributes: {
      [key: string]: string;
    };
  }>;
};
const getTokenInfoByAddresses = async (addresses: string) => {
  if (!addresses) {
    return;
  }
  try {
    const { data } = await axios.get<void, AxiosResponse<CoinGeckoTokenInfo>>(
      `/api/getCoinGeckoTokensInfo?addresses=${encodeURIComponent(addresses)}`
    );

    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status === 404) {
        return null;
      } else {
        throw new Error(error.message);
      }
    }
    throw error;
  }
};

export const useCoinGeckoTokenInfo = (addresses: string) => {
  return useQuery<CoinGeckoTokenInfo | undefined | null>({
    queryKey: [QueryKeys.COINGECKO_TOKEN_INFO, addresses],
    queryFn: async () => {
      return getTokenInfoByAddresses(addresses);
    },
    enabled: !!addresses,
  });
};
