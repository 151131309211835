import { mainnet, sepolia } from 'wagmi/chains';
import { Chain } from 'viem';
import localConfig from '@/config';

export const mode = {
  id: 34443,
  name: 'Mode',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://mainnet.mode.network'],
    },
    public: {
      http: ['https://mainnet.mode.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://explorer.mode.network',
    },
  },
  contracts: {},
  testnet: false,
} as const satisfies Chain;

export const mainnetUpdated = {
  ...mainnet,
  rpcUrls: {
    default: {
      http: [localConfig.l1Rpc],
    },
    public: {
      http: [localConfig.l1Rpc],
    },
  },
} as const satisfies Chain;

export const modeTestnet = {
  id: 919,
  name: 'Mode-sepolia',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://sepolia.mode.network'],
    },
    public: {
      http: ['https://sepolia.mode.network'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://sepolia.explorer.mode.network',
    },
  },
  contracts: {},
  testnet: true,
} as const satisfies Chain;

export const sepoliaUpdated = {
  ...sepolia,
  rpcUrls: {
    default: {
      http: [localConfig.l1Rpc],
    },
    public: {
      http: [localConfig.l1Rpc],
    },
  },
} as const satisfies Chain;
