'use client';

import localConfig from '@/config';
import { RPC_OVERRIDE_KEY_L1, RPC_OVERRIDE_KEY_L2 } from '@/constants/common';
import { NETWORKS } from '@/constants/networks';

export const getActiveRpc = (
  key: string,
  defaultRpc: {
    [key: string]: {
      http: readonly string[];
    };
    default: {
      http: readonly string[];
    };
    public: {
      http: readonly string[];
    };
  }
) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    const rpcOverride = localStorage.getItem(key);

    if (rpcOverride) {
      const rpc = JSON.parse(rpcOverride) as {
        label: string;
        value: string[];
      };

      if (rpc.label === 'Llama') {
        localStorage.removeItem(RPC_OVERRIDE_KEY_L1);
      } else if (rpc.label === '1rpc') {
        localStorage.removeItem(RPC_OVERRIDE_KEY_L2);
      } else if (rpc && Array.isArray(rpc.value) && rpc.value.length > 0) {
        return {
          default: {
            http: rpc.value,
          },
          public: {
            http: rpc.value,
          },
        };
      }
    }

    return defaultRpc;
  } catch (e: unknown) {
    console.error(e);
    return defaultRpc;
  }
};

export const getNetworkIcon = (chainId: number) =>
  NETWORKS[localConfig.modeEnv].find((item) => item.chainId === chainId)?.icon || '';
