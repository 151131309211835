'use client';

import { useLocalizationContext } from '@/app/context/LocalizationContext';
import { useGuidesItems } from '@/hooks/content';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { Tooltip } from './Tooltip';

export interface GuideContextType {
  showGuideById: (id: string, force?: boolean) => void;
}

export const GuideContext = createContext<GuideContextType | undefined>(undefined);

export const useGuide = (): GuideContextType => {
  const context = useContext(GuideContext);

  if (!context) {
    throw new Error('useGuide must be used within a GuideProvider');
  }

  return context;
};

export interface GuideProviderProps {
  children: ReactNode;
}

export const GuideProvider: React.FC<GuideProviderProps> = ({ children }) => {
  const [guideId, setGuideId] = useState<string | null>(null);
  const { data: guides } = useGuidesItems();
  const { locale } = useLocalizationContext();

  const steps = useMemo(() => {
    if (!guides || !guideId) {
      return;
    }

    const item = guides.find((guide) => guide.id === guideId);
    return (item?.steps?.[locale] ?? []) as unknown as Step[];
  }, [guides, guideId, locale]);

  const showGuideById = useCallback((id: string, force = true) => {
    // This clause is not yet included in the requirements, but we can use it in the future.
    const hasSeenTour = localStorage.getItem(`hasSeenTour_${id}`);

    if (!hasSeenTour || force) {
      setGuideId(id);
    }
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      localStorage.setItem(`hasSeenTour_${guideId}`, 'true');
      setGuideId(null);
    }
  };

  return (
    <GuideContext.Provider value={{ showGuideById }}>
      {children}
      {guideId && (
        <Joyride
          steps={steps}
          run={!!guideId}
          spotlightClicks
          callback={handleJoyrideCallback}
          tooltipComponent={Tooltip}
          styles={{
            options: {
              arrowColor: '#DFFE00',
            },
          }}
        />
      )}
    </GuideContext.Provider>
  );
};
