import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@/constants/queryKeys';
import axios, { AxiosResponse } from 'axios';

export async function getUserLocation() {
  const { data } = await axios.get<void, AxiosResponse<{ country: string }>>(`/api/geo/`);

  return data;
}

const fetcher = async () => {
  return getUserLocation();
};

export const useGeoLocation = (options = {}) => {
  return useQuery<{ country: string } | undefined>({
    queryKey: [QueryKeys.GEO_LOCATION],
    queryFn: async () => {
      return fetcher();
    },
    ...options,
  });
};
