import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils/contentful';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { AssetEntry, AssetSkeleton } from '../types';

const fetchAssets = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.getEntries<AssetSkeleton>({
          content_type: 'asset',
        })
      ).items;
    } else {
      content = (await axios.get<void, AxiosResponse<AssetEntry[]>>(`${localConfig.contentfulUrl}/assets.json`)).data;
    }

    return content
      .map((entry) => {
        const { name, id, logo, code, pointsBoost } = entry.fields;

        return {
          name,
          id,
          logoSrc: logo.fields.file ? `https:${logo.fields.file.url}` : '',
          code,
          pointsBoost: `${pointsBoost.toFixed(2)} X`,
        };
      })
      .sort((a, b) => a.id - b.id);
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useAssets = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.ASSETS],
    queryFn: fetchAssets,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};

export type Asset = NonNullable<Awaited<ReturnType<typeof fetchAssets>>>[number];
