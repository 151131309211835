import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { ThirdPartyBridgeEntry, ThirdPartyBridgeSkeleton } from '../types';

const fetchThirdPartyBridges = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.getEntries<ThirdPartyBridgeSkeleton>({
          content_type: 'thirdPartyBridge',
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<ThirdPartyBridgeEntry[]>>(
          `${localConfig.contentfulUrl}/thirdPartyBridges.json`
        )
      ).data;
    }

    return content.map((entry) => {
      const {
        name,
        id,
        logo,
        link,
        transactionEstimatedTimeDeposit,
        transactionEstimatedTimeWithdrawal,
        supportedTokenCodes,
        supportedNetworkIds,
      } = entry.fields;

      return {
        name,
        id,
        link,
        logoSrc: logo?.fields?.file ? `https:${logo.fields.file.url}` : '',
        transactionEstimatedTimeDeposit,
        transactionEstimatedTimeWithdrawal,
        supportedTokenCodes,
        supportedNetworkIds,
      };
    });
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useThirdPartyBridges = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.THIRD_PARTY_BRIDGES],
    queryFn: fetchThirdPartyBridges,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};

export type ThirdPartyBridge = NonNullable<Awaited<ReturnType<typeof fetchThirdPartyBridges>>>[number];
