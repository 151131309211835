export const BridgeIcon = ({ size = 24, className }: { size?: number; className?: string }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5C7 6.65685 5.65685 8 4 8V10C5.12561 10 6.16434 9.62806 7 9.00037V12H4V14H7V19H9V14H15V19H17V14H20V12H17V9.00036C17.8357 9.62805 18.8744 10 20 10V8C18.3431 8 17 6.65685 17 5H15C15 6.65685 13.6569 8 12 8C10.3431 8 9 6.65685 9 5H7ZM15 12V9.00037C14.1643 9.62806 13.1256 10 12 10C10.8744 10 9.83566 9.62806 9 9.00037V12H15Z"
        fill="currentColor"
      />
    </svg>
  );
};
