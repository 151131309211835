'use client';

import { createContext, useContext } from 'react';

export const LocalizationContext = createContext<LocalizationContextValue | undefined>(undefined);

export enum Locale {
  enUS = 'en-US',
  ukUA = 'uk-UA',
}

export const baseLocale = Locale.enUS;

export const locales: Locale[] = [Locale.enUS, Locale.ukUA];

export type LocalizationContextValue = {
  locale: Locale;
  setLocale: (locale: Locale) => void;
};

export function useLocalizationContext() {
  const context = useContext(LocalizationContext);

  if (context === undefined) {
    throw new Error('useLocalizationContext must be used within a LocalizationContext');
  }

  return context;
}
