import { MODE_ENV } from '@/constants/common';
import localConfig from '@/config';
import { Network } from '@ethersproject/networks';

export interface NetworkOption extends Network {
  icon: string;
}

export const NETWORKS: Record<string, NetworkOption[]> = {
  [MODE_ENV.MAINNET]: [
    {
      chainId: +localConfig.l1ChainId,
      name: 'Ethereum',
      icon: '/assets/icons/eth_blue.svg',
    },
    {
      chainId: +localConfig.l2ChainId,
      name: 'Mode',
      icon: '/assets/icons/mode_yellow.svg',
    },
    {
      chainId: 10,
      name: 'Optimism',
      icon: '/assets/icons/optimism.svg',
    },
    {
      chainId: 8453,
      name: 'Base',
      icon: '/assets/icons/base.svg',
    },
    {
      chainId: 42161,
      name: 'Arbitrum',
      icon: '/assets/icons/arbitrum.svg',
    },
    {
      chainId: 137,
      name: 'Polygon',
      icon: '/assets/icons/polygon.svg',
    },
  ],
  [MODE_ENV.TESTNET]: [
    {
      chainId: +localConfig.l1ChainId,
      name: 'Sepolia',
      icon: '/assets/icons/eth_blue.svg',
    },
    {
      chainId: +localConfig.l2ChainId,
      name: 'Mode testnet',
      icon: '/assets/icons/mode_yellow.svg',
    },
  ],
};
