export const ProveIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="mask0_40922_28940" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_40922_28940)">
        <path
          d="M12 16C13.25 16 14.3125 15.5625 15.1875 14.6875C16.0625 13.8125 16.5 12.75 16.5 11.5C16.5 10.25 16.0625 9.1875 15.1875 8.3125C14.3125 7.4375 13.25 7 12 7V8.5C12.8333 8.5 13.5417 8.79167 14.125 9.375C14.7083 9.95833 15 10.6667 15 11.5C15 12.3333 14.7083 13.0417 14.125 13.625C13.5417 14.2083 12.8333 14.5 12 14.5C11.45 14.5 10.9375 14.3583 10.4625 14.075C9.9875 13.7917 9.61667 13.4083 9.35 12.925L8.05 13.65C8.45 14.3833 9 14.9583 9.7 15.375C10.4 15.7917 11.1667 16 12 16ZM8.25 12.35C8.46667 12.35 8.64583 12.2792 8.7875 12.1375C8.92917 11.9958 9 11.8167 9 11.6C9 11.3833 8.92917 11.2042 8.7875 11.0625C8.64583 10.9208 8.46667 10.85 8.25 10.85C8.03333 10.85 7.85417 10.9208 7.7125 11.0625C7.57083 11.2042 7.5 11.3833 7.5 11.6C7.5 11.8167 7.57083 11.9958 7.7125 12.1375C7.85417 12.2792 8.03333 12.35 8.25 12.35ZM8.8 10.25C9.01667 10.25 9.19583 10.1792 9.3375 10.0375C9.47917 9.89583 9.55 9.71667 9.55 9.5C9.55 9.28333 9.47917 9.10417 9.3375 8.9625C9.19583 8.82083 9.01667 8.75 8.8 8.75C8.58333 8.75 8.40417 8.82083 8.2625 8.9625C8.12083 9.10417 8.05 9.28333 8.05 9.5C8.05 9.71667 8.12083 9.89583 8.2625 10.0375C8.40417 10.1792 8.58333 10.25 8.8 10.25ZM10.25 8.875C10.4667 8.875 10.6458 8.80417 10.7875 8.6625C10.9292 8.52083 11 8.34167 11 8.125C11 7.90833 10.9292 7.72917 10.7875 7.5875C10.6458 7.44583 10.4667 7.375 10.25 7.375C10.0333 7.375 9.85417 7.44583 9.7125 7.5875C9.57083 7.72917 9.5 7.90833 9.5 8.125C9.5 8.34167 9.57083 8.52083 9.7125 8.6625C9.85417 8.80417 10.0333 8.875 10.25 8.875ZM12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22ZM12 19.9C13.7333 19.35 15.1667 18.25 16.3 16.6C17.4333 14.95 18 13.1167 18 11.1V6.375L12 4.125L6 6.375V11.1C6 13.1167 6.56667 14.95 7.7 16.6C8.83333 18.25 10.2667 19.35 12 19.9Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
