export const ArrowIcon = ({ className, size = 24 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5858 11L12.2929 7.70706L13.7071 6.29285L18.7071 11.2929L19.4142 12L18.7071 12.7071L13.7071 17.7071L12.2929 16.2929L15.5858 13H6V11H15.5858Z"
        fill="currentColor"
      />
    </svg>
  );
};
