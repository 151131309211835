import localConfig from '@/config';
import uniq from 'lodash.uniq';

export type Currency = {
  label: string;
  logo: string;
  l1: string;
  l2: string;
  chains: string[];
  decimals: number;
  l1Bridge?: string;
  l2Bridge?: string;
};

type TokenOption = {
  contractAddress: string;
  icon: string;
  symbol: string;
  decimals: number;
  bridge?: string;
};

const DEFAULT_DECIMALS = 18; // Default Decimals in ERC-20 Tokens

const _currencies = {
  l1: localConfig.whitelistedTokens[localConfig.l1ChainId] as TokenOption[],
  l2: localConfig.whitelistedTokens[localConfig.l2ChainId] as TokenOption[],
};

const uniqSymbols = uniq([..._currencies.l1.map((item) => item.symbol), ..._currencies.l2.map((item) => item.symbol)]);

export const currencyOptions: Currency[] = [
  // TODO: remove when we whitelist $Mode token
  {
    label: 'MODE',
    logo: '/assets/icons/mode_yellow.svg',
    l1: '',
    l2: '0xDfc7C877a950e49D2610114102175A06C2e3167a',
    chains: ['MAINNET'],
    decimals: DEFAULT_DECIMALS,
  },
  ...uniqSymbols.map((symbol) => {
    const l1Item = _currencies.l1.find((item) => item.symbol === symbol);
    const l2Item = _currencies.l2.find((item) => item.symbol === symbol);

    return {
      label: symbol ?? '',
      logo: l1Item?.icon ?? l2Item?.icon ?? '',
      l1: l1Item?.contractAddress ?? '',
      l2: l2Item?.contractAddress ?? '',
      chains: [localConfig.modeEnv],
      decimals: l1Item?.decimals ?? l2Item?.decimals ?? DEFAULT_DECIMALS,
      l1Bridge: l1Item?.bridge,
      l2Bridge: l2Item?.bridge,
    };
  }),
];

export interface NetworkOption {
  label: string;
  logo: string;
}

export const networkOptions: NetworkOption[] = [
  {
    label: 'Ethereum',
    logo: '/assets/icons/eth_blue.svg',
  },
];

export const AmountInputColorTheme = {
  red: {
    border: 'border-[#F12150]',
    text: 'text-[#F12150]',
    background: 'bg-neutral-900',
  },
  orange: {
    border: 'border-orange-500',
    text: 'text-neutral-100',
    background: 'bg-neutral-900',
  },
};
