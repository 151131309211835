import Image from 'next/image';
import { UnknownTokenIcon } from '@/components/icons';
import { currencyOptions } from '@/constants';

export const tokenNames = [
  {
    symbol: 'USDT',
    name: 'Tether',
  },
  {
    symbol: 'USDC',
    name: 'USD Coin',
  },
  {
    symbol: 'ETH',
    name: 'Ethereum',
  },
  {
    symbol: 'mBTC',
    name: 'Merlin BTC',
  },
  {
    symbol: 'ezETH',
    name: 'Renzo restaked ETH',
  },
  {
    symbol: 'WBTC',
    name: 'Wrapped Bitcoin',
  },
  {
    symbol: 'weETH.mode',
    name: 'weETH.mode',
  },
];

export const getTokenIconBySymbol = (symbol: string) => {
  const iconSrc = currencyOptions.find((token) => token.label === symbol)?.logo;

  if (iconSrc) return <Image src={iconSrc} alt={symbol} width={28} height={28} />;
  return <UnknownTokenIcon size={28} />;
};
