import { baseLocale } from '@/app/context';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { RestakingBannerEntry, RestakingBannerSkeleton } from '../types';

const fetchBanners = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<RestakingBannerSkeleton>({
          content_type: 'restakingBanner',
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<RestakingBannerEntry[]>>(
          `${localConfig.contentfulUrl}/restakingBannersWithAllLocales.json`
        )
      ).data;
    }

    return content
      .map((entry) => {
        const {
          name,
          id,
          logo,
          link,
          shortDescription,
          badges,
          primaryButtonLink,
          primaryButtonText,
          docsLink,
          photonId,
          orbId,
          projectTotalNft,
          safeAddress,
        } = entry.fields;

        return {
          name: name[baseLocale],
          id: id[baseLocale],
          logoSrc: `https:${logo?.[baseLocale]?.file?.[baseLocale]?.url}`,
          link: link[baseLocale],
          primaryButtonLink: primaryButtonLink?.[baseLocale],
          primaryButtonText,
          docsLink: docsLink?.[baseLocale],
          shortDescription,
          badges: badges?.[baseLocale] ?? [],
          photonId: photonId?.[baseLocale],
          orbId: orbId?.[baseLocale],
          projectTotalNft: projectTotalNft?.[baseLocale],
          safeAddress: safeAddress?.[baseLocale],
        };
      })
      .sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useRestakingBanners = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.RESTAKING_BANNERS],
    queryFn: fetchBanners,
    refetchOnWindowFocus: localConfig.showContentLive,
    ...options,
  });
};

export type RestakingBanner = NonNullable<Awaited<ReturnType<typeof fetchBanners>>>[number];
