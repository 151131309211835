export const CoinsIcon = ({ className, size = 16 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.0026 3C3.97756 3 2.33594 4.64162 2.33594 6.66667C2.33594 8.49834 3.67913 10.0164 5.4341 10.2896C5.36973 9.98063 5.33594 9.66075 5.33594 9.33333C5.33594 7.05485 6.96873 5.15786 9.12822 4.74848C8.48261 3.69878 7.3238 3 6.0026 3ZM10.2225 4.67176C9.47509 3.09335 7.86704 2 6.0026 2C3.42528 2 1.33594 4.08934 1.33594 6.66667C1.33594 9.17029 3.30736 11.2133 5.78275 11.3282C6.53037 12.907 8.13829 14 10.0026 14C12.5799 14 14.6693 11.9107 14.6693 9.33333C14.6693 6.8297 12.6977 4.78655 10.2225 4.67176ZM10.0026 5.66667C9.96987 5.66667 9.93724 5.66709 9.90473 5.66794C7.92507 5.71969 6.33594 7.34101 6.33594 9.33333C6.33594 9.7991 6.42254 10.2435 6.58006 10.652C7.10997 12.0263 8.4432 13 10.0026 13C12.0276 13 13.6693 11.3584 13.6693 9.33333C13.6693 7.30829 12.0276 5.66667 10.0026 5.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};
