export const CheckIcon = ({
  className,
  height = 6,
  width = 8,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.99997 4.29294L6.64642 0.646484L7.35353 1.35359L3.35353 5.35359L2.99998 5.70714L2.64642 5.3536L0.146362 2.8536L0.85346 2.14648L2.99997 4.29294Z"
        fill="currentColor"
      />
    </svg>
  );
};
