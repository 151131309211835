export const ModeWhiteIcon = ({ className, size = 16 }: { className?: string; size?: number }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM12.095 11.6227H10.4981V8.01125L11.1375 5.95171L10.6845 5.79106L8.61207 11.6227H7.39756L5.32518 5.79106L4.87214 5.95171L5.51153 8.01125V11.6227H3.91467V4.37734H6.29229L7.76706 8.52533V9.74305H8.24901V8.52533L9.72377 4.37734H12.1014V11.6227H12.095Z"
        fill="#F8F8F8"
      />
    </svg>
  );
};
