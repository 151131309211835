'use client';

import { ReactNode, useEffect, useState } from 'react';
import { Locale, LocalizationContext } from './LocalizationContext';

export const LocalizationContextProvider = ({ children }: { children: ReactNode }) => {
  const [locale, setLocale] = useState<Locale>(Locale.enUS);
  useEffect(() => {
    // Perform localStorage action
    setLocale((localStorage.getItem('locale') as Locale) || Locale.enUS);
  }, []);

  return (
    <LocalizationContext.Provider
      value={{
        locale,
        setLocale,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
