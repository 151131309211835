import { baseLocale } from '@/app/context';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { SeasonalBannerEntry, SeasonalBannerSkeleton } from '../types';

const fetchSeasonalBanners = async () => {
  try {
    let content = [];
    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<SeasonalBannerSkeleton>({
          content_type: 'seasonalBanners',
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<SeasonalBannerEntry[]>>(`${localConfig.contentfulUrl}/seasonalBanners.json`)
      ).data;
    }

    return content
      .map((entry) => {
        const {
          id,
          title,
          text,
          label,
          labelColor,
          buttonText,
          buttonLink,
          icon,
          backgroundImage,
          isLink,
          isSelectedUsersGroup,
          replaceWithId,
          extraButtonText,
          extraButtonLink,
        } = entry.fields;

        return {
          id: id[baseLocale],
          title: title[baseLocale],
          text: text?.[baseLocale],
          label: label?.[baseLocale],
          labelColor: labelColor?.[baseLocale],
          buttonText: buttonText?.[baseLocale],
          extraButtonText: extraButtonText?.[baseLocale],
          buttonLink: buttonLink?.[baseLocale],
          extraButtonLink: extraButtonLink?.[baseLocale],
          icon: icon?.[baseLocale]?.fields?.file?.[baseLocale]?.url
            ? `https:${icon[baseLocale].fields.file[baseLocale].url}`
            : '',
          backgroundImage: backgroundImage?.[baseLocale]?.fields?.file?.[baseLocale]?.url
            ? `https:${backgroundImage[baseLocale].fields.file[baseLocale].url}`
            : '',
          isAnchor: isLink?.[baseLocale] ?? false,
          isSelectedUsersGroup: isSelectedUsersGroup?.[baseLocale] ?? false,
          replaceWithId: replaceWithId?.[baseLocale] ?? -1,
        };
      })
      .sort((a, b) => (a.id ?? 0) - (b.id ?? 0));
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useSeasonalBanners = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.SEASONAL_BANNERS],
    queryFn: fetchSeasonalBanners,
    ...options,
  });
};

export type SeasonalBanner = NonNullable<Awaited<ReturnType<typeof fetchSeasonalBanners>>>[number];
