export const opStackL1Contracts_MAINNET = {
  L1ERC721Bridge: '0x8b91Af069928bA6591c950354d1EA29e08192Bf8',
  L1StandardBridge: '0x9c67ACcb38137CB761587032179b176c9276Eb5a',
  Lib_AddressManager: '0x50eF494573f28Cad6B64C31b7a00Cdaa48306e15',
  L2OutputOracleProxy: '0x4317ba146D4933D889518a3e5E11Fe7a53199b04',
  OptimismMintableERC20FactoryProxy: '0x69216395A62dFb243C05EF4F1C27AF8655096a95',
  Proxy__OVM_L1StandardBridge: '0x735aDBbE72226BD52e818E7181953f42E3b0FF21',
  ProxyAdmin: '0x470d87b1dae09a454A43D1fD772A561a03276aB7',
  OptimismMintableERC20Factory: '0xc0c6A811BBf07FA01b946F1C46a9A94c2eE8C73E',
  OptimismPortal: '0xad3DC277d3242938F8Be18f0560e3d9B9988C46A',
  Proxy__OVM_L1CrossDomainMessenger: '0x95bDCA6c8EdEB69C98Bd5bd17660BaCef1298A6f',
  L1ERC721BridgeProxy: '0x2901dA832a4D0297FF0691100A8E496626cc626D',
  SystemConfig: '0x951754B08C52b2aC5d5a2aF1D52C2D12aED5Bcaf',
  OptimismPortalProxy: '0x8B34b14c7c7123459Cf3076b8Cb929BE097d0C07',
  L1CrossDomainMessenger: '0x14DdD08c0e28764FC89a266eC95A93619b0EE835',
  L2OutputOracle: '0x6093023a4A7E6873EDFb02B4bCE48c53FD310EEc',
  SystemConfigProxy: '0x5e6432F18Bc5d497B1Ab2288a025Fbf9D69E2221',
};

export const opStackL1Contracts_TESTNET = {
  L1ERC721Bridge: '0x7Bf471d9181AD783c7510243D1B0EBc6f29e9a81',
  SystemDictator: '0x92fE7f11452D8fff92ebfFb47036e0443ea110F1',
  PortalSender: '0xd1b3CBdB812712d71F5440Af34bf605b34b6c21C',
  L1StandardBridge: '0x98C41994F0b4DcCD52fad6BfeA6615de34C2ce0f',
  Lib_AddressManager: '0x83D45725d6562d8CD717673D6bb4c67C07dC1905',
  L2OutputOracleProxy: '0x2634BD65ba27AB63811c74A63118ACb312701Bfa',
  OptimismMintableERC20FactoryProxy: '0x00F7ab8c72D32f55cFf15e8901C2F9f2BF29A3C0',
  Proxy__OVM_L1StandardBridge: '0xbC5C679879B2965296756CD959C3C739769995E2',
  ProxyAdmin: '0xE7413127F29E050Df65ac3FC9335F85bB10091AE',
  OptimismMintableERC20Factory: '0x0f5908861962DfFaB7e4d9a1c9F513F1988E8dCC',
  OptimismPortal: '0xd9B9DCE20d7837379cbD4Fa1a58Bc70E595bb9a5',
  Proxy__OVM_L1CrossDomainMessenger: '0xc19a60d9E8C27B9A43527c3283B4dd8eDC8bE15C',
  L1ERC721BridgeProxy: '0x015a8c2e0a5fEd579dbb05fd290e413Adc6FC24A',
  SystemConfig: '0x2238382DCEA03f024993c8a0355917c80B20301F',
  OptimismPortalProxy: '0x320e1580effF37E008F1C92700d1eBa47c1B23fD',
  L1CrossDomainMessenger: '0x9B800c1e8b61Aa9D141BCD317dDe7849F7A043E5',
  L2OutputOracle: '0xEeBD920aE8444C4F5d6aD07879cCBeb53663d93A',
  SystemDictatorProxy: '0x20c608922171A06A7bC5448acf6197fEE7448a8b',
  SystemConfigProxy: '0x15cd4f6e0CE3B4832B33cB9c6f6Fe6fc246754c2',
};
