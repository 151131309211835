if (
  !(
    process.env.NEXT_PUBLIC_SFS_BASE_URL &&
    process.env.NEXT_PUBLIC_FEE_SHARING_CONTRACT_ADDRESS &&
    process.env.NEXT_PUBLIC_BACKEND_API_URL &&
    process.env.NEXT_PUBLIC_L1_BRIDGE_ADDRESS &&
    process.env.NEXT_PUBLIC_L1_CHAIN_ID &&
    process.env.NEXT_PUBLIC_L1_RPC &&
    process.env.NEXT_PUBLIC_L2_BRIDGE_ADDRESS &&
    process.env.NEXT_PUBLIC_L2_CHAIN_ID &&
    process.env.NEXT_PUBLIC_L2_RPC &&
    process.env.NEXT_PUBLIC_EXPLORER_URL &&
    process.env.NEXT_PUBLIC_ETH_DEPOSIT_GAS_LIMIT &&
    process.env.NEXT_PUBLIC_TOKEN_DEPOSIT_GAS_LIMIT &&
    process.env.NEXT_PUBLIC_POSTHOG_HOST &&
    process.env.NEXT_PUBLIC_APP_URL &&
    process.env.NEXT_PUBLIC_MODE_ENV &&
    process.env.NEXT_PUBLIC_CONTENTFUL_URL &&
    process.env.NEXT_PUBLIC_SHOW_CONTENT_LIVE &&
    process.env.NEXT_PUBLIC_CONTENTFUL_SPACE &&
    process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN &&
    process.env.NEXT_PUBLIC_CONTENTFUL_HOST &&
    process.env.NEXT_PUBLIC_BRIDGE_ENV &&
    process.env.NEXT_PUBLIC_BRIDGE_TESTNET_URL &&
    process.env.NEXT_PUBLIC_BRIDGE_MAINNET_URL &&
    process.env.NEXT_PUBLIC_MODE_DROP_SUBGRAPH_URL &&
    process.env.NEXT_PUBLIC_NFT_FACTORY_ADDRESS &&
    process.env.NEXT_PUBLIC_LOCK_ADDRESS
  )
) {
  throw new Error('Local config is missing variables!');
}

const localConfig = {
  sfsBaseUrl: process.env.NEXT_PUBLIC_SFS_BASE_URL,
  feeSharingContractAddress: process.env.NEXT_PUBLIC_FEE_SHARING_CONTRACT_ADDRESS,
  apiUrl: process.env.NEXT_PUBLIC_BACKEND_API_URL,
  explorerUrl: process.env.NEXT_PUBLIC_EXPLORER_URL,
  etherscanExplorerUrl: process.env.NEXT_PUBLIC_ETHERSCAN_EXPLORER_URL,
  ethDepositGasLimit: process.env.NEXT_PUBLIC_ETH_DEPOSIT_GAS_LIMIT,
  tokenDepositGasLimit: process.env.NEXT_PUBLIC_TOKEN_DEPOSIT_GAS_LIMIT,
  posthogHost: process.env.NEXT_PUBLIC_POSTHOG_HOST,
  appUrl: process.env.NEXT_PUBLIC_APP_URL,
  showDevelopersPage: process.env.NEXT_PUBLIC_SHOW_DEVELOPERS_PAGE,
  showDashboardPage: process.env.NEXT_PUBLIC_SHOW_DASHBOARD_PAGE,
  showLanguageSwitcher: process.env.NEXT_PUBLIC_SHOW_LANGUAGE_SWITCHER,
  boostedRewardsPeriod: process.env.NEXT_PUBLIC_BOOSTED_REWARD_PERIOD,
  showMaintenanceInfo: process.env.NEXT_PUBLIC_SHOW_MAINTENANCE_INFO,
  contentfulUrl: process.env.NEXT_PUBLIC_CONTENTFUL_URL,
  showContentLive: process.env.NEXT_PUBLIC_SHOW_CONTENT_LIVE === 'true',
  contentfulSpace: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE,
  contentfulToken: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN,
  contentfulHost: process.env.NEXT_PUBLIC_CONTENTFUL_HOST,
  showPintsBanner: process.env.NEXT_PUBLIC_SHOW_POINTS_BANNER,
  showAIPage: process.env.NEXT_PUBLIC_SHOW_AI_PAGE,

  modeEnv: process.env.NEXT_PUBLIC_MODE_ENV,
  bridgeEnv: process.env.NEXT_PUBLIC_BRIDGE_ENV,

  // L1
  l1BridgeAddress: process.env.NEXT_PUBLIC_L1_BRIDGE_ADDRESS,
  l1ChainId: process.env.NEXT_PUBLIC_L1_CHAIN_ID,
  l1Rpc: process.env.NEXT_PUBLIC_L1_RPC,

  // L2
  l2BridgeAddress: process.env.NEXT_PUBLIC_L2_BRIDGE_ADDRESS,
  l2ChainId: process.env.NEXT_PUBLIC_L2_CHAIN_ID,
  l2Rpc: process.env.NEXT_PUBLIC_L2_RPC,

  // Tokens
  whitelistedTokens: process.env.NEXT_PUBLIC_WHITELISTED_TOKENS
    ? JSON.parse(process.env.NEXT_PUBLIC_WHITELISTED_TOKENS)
    : null,

  // Bridge URLs
  bridgeTestnetUrl: process.env.NEXT_PUBLIC_BRIDGE_TESTNET_URL,
  bridgeMainnettUrl: process.env.NEXT_PUBLIC_BRIDGE_MAINNET_URL,

  // Mode DevDrop subgraph
  modeDropSubgraphUrl: process.env.NEXT_PUBLIC_MODE_DROP_SUBGRAPH_URL,
  // Mode DevDrop factory address
  nftFactoryAddress: process.env.NEXT_PUBLIC_NFT_FACTORY_ADDRESS,

  // Lock
  lockAddress: process.env.NEXT_PUBLIC_LOCK_ADDRESS,
  modeTokenAddress: process.env.NEXT_PUBLIC_MODE_TOKEN_ADDRESS,
  showLockSection: process.env.NEXT_PUBLIC_SHOW_LOCK_SECTION,

  lockModeUsdcBptAddress: process.env.NEXT_PUBLIC_LOCK_MODE_USDC_BPT_ADDRESS,
  modeModeUsdcBptTokenAddress: process.env.NEXT_PUBLIC_MODE_USDC_BPT_TOKEN_ADDRESS,

  // Lock season 3
  createLockAddress: process.env.NEXT_PUBLIC_CREATE_LOCK_ADDRESS,
  createLockModeUsdcBptAddress: process.env.NEXT_PUBLIC_CREATE_LOCK_MODE_USDC_BPT_ADDRESS,

  // show OP Banner
  showOPBanner: process.env.NEXT_PUBLIC_SHOW_OP_BANNER,

  // show custom page title
  pageTitle: process.env.NEXT_PUBLIC_PAGE_TITLE,

  showPointsPage: process.env.NEXT_PUBLIC_SHOW_POINTS_PAGE,
};

export default localConfig;
